import { PromiseResults, UserList } from '@/global-types'
import Vue from 'vue'
import { getResult } from './utils'

const http = Vue.prototype.$http
// const storageKey = 'user-lists'
const eventKey = 'user-lists-updated'

interface updateInterface {
  label: string|null;
  mrNumbers: number[]|null;
}

export const UserListsAPI = {
  storageKey: 'user-lists',
  lastUsedKey: 'user-lists-last-used-id',
  /* eslint-disable @typescript-eslint/ban-types */
  setupEventListener(func:Function): void {
    window.addEventListener(eventKey, (e) => {
      func(e)
    })
  },

  pullFromLocalStorage(): UserList[] {
    const stringStoredLists = localStorage.getItem(this.storageKey)
    let storedLists: UserList[] = []
    if (stringStoredLists) {
      storedLists = JSON.parse(stringStoredLists)
    }
    return storedLists
  },

  clearLocalStorage(): void {
    localStorage.setItem(this.storageKey, '')
    const event = new CustomEvent(eventKey)
    window.dispatchEvent(event)
  },

  updateUserList(userlist:UserList): void {
    // Update the local storage copy of the list
    const stringStoredLists = localStorage.getItem(this.storageKey)
    let storedLists: UserList[] = []
    if (stringStoredLists) {
      storedLists = JSON.parse(stringStoredLists)
    }
    if (storedLists.length > 0 && userlist.id && storedLists.map(x => x.id).indexOf(userlist.id) > -1) {
      // Remove the old list from the stored
      storedLists = storedLists.filter(x => x.id !== userlist.id)
    }
    storedLists.push(userlist)
    localStorage.setItem(this.storageKey, JSON.stringify(storedLists))
    const event = new CustomEvent(eventKey)
    window.dispatchEvent(event)
  },

  async getLists(): PromiseResults<UserList[]> {
    const data = await http.get('/api/mr-lists')
    localStorage.setItem(this.storageKey, JSON.stringify(data.data))
    const event = new CustomEvent(eventKey)
    window.dispatchEvent(event)
    return data.data
  },

  async getList(id:string): PromiseResults<UserList> {
    // Stores the list in localStorage
    const data = await http.get('/api/mr-lists/' + id)
    this.updateUserList(data.data)
    return data.data
  },

  async getSingleList(id:string): Promise<UserList> {
    // Doesn't store the list in local storage
    return await getResult('/api/mr-lists/' + id)
  },

  async createList(list:UserList): Promise<void> {
    const data = await http.post('/api/mr-lists', list)
    this.updateUserList(data.data)
  },

  async updateList(id:string, label?:string, mrNumbers?:number[]): Promise<void> {
    // Can have label, mrNumbers, or both
    const updateData:updateInterface = {
      label: null,
      mrNumbers: null,
    }
    if (label) {
      updateData.label = label
    }
    if (mrNumbers) {
      updateData.mrNumbers = mrNumbers
    }
    const data = await http.patch('/api/mr-lists/' + id, updateData)
    this.updateUserList(data.data)
  },

  async deleteList(id:string): Promise<void> {
    await http.delete('/api/mr-lists/' + id)
    const stringStoredLists = localStorage.getItem(this.storageKey)
    if (!stringStoredLists) {
      return
    }
    let storedLists: UserList[] = JSON.parse(stringStoredLists)
    if (storedLists.map(x => x.id).indexOf(id) > -1) {
      // Remove the old version from the store
      storedLists = storedLists.filter(x => x.id !== id)
    }
    localStorage.setItem(this.storageKey, JSON.stringify(storedLists))
    const event = new CustomEvent(eventKey)
    window.dispatchEvent(event)
  },

  updateLastUsedUserList(id:string): void {
    localStorage.setItem(this.lastUsedKey, id)
  },

  getLastUsedUserList(): string {
    const lastId = localStorage.getItem(this.lastUsedKey)
    if (lastId) {
      return lastId
    }
    return '' // Empty string = local storage clipboard user list
  },
}
