import { getResult } from './utils'
import { PromiseResults, SerialMinimal } from '@/global-types'
import { Journal } from '@/components/search-results/journal-results'
import { Series } from '@/components/search-results/series-results'
import { SerialsSuggestion } from '@/components/serials-suggestions'

export const SerialsAPI = {
  searchJournals(query: string, currentPage: number, pageSize: number, sort?: string, order?: string, includeCurrent?: boolean, includeHistoric?: boolean): PromiseResults<Journal> {
    if (includeCurrent === undefined || includeCurrent === null) {
      includeCurrent = true
    }
    if (includeHistoric === undefined || includeHistoric === null) {
      includeHistoric = false
    }
    if (!(includeCurrent && includeHistoric)) {
      // If it includes both, leave the act:"" out of the query all together
      if (includeCurrent) {
        query = query + ' act:"CURRENT"'
      }
      if (includeHistoric) {
        query = query + ' act:"HISTORICAL"'
      }
    }
    return getResult('/api/serials/journal/search', { query, currentPage, pageSize, sort, order })
  },

  searchSeries(query: string, currentPage: number, pageSize: number, sort?: string, order?: string, includeCurrent?: boolean, includeHistoric?: boolean): PromiseResults<Series> {
    if (includeCurrent === undefined || includeCurrent === null) {
      includeCurrent = true
    }
    if (includeHistoric === undefined || includeHistoric === null) {
      includeHistoric = false
    }
    if (!(includeCurrent && includeHistoric)) {
      // If it includes both, leave the act:"" out of the query all together
      if (includeCurrent) {
        query = query + ' act:"CURRENT"'
      }
      if (includeHistoric) {
        query = query + ' act:"HISTORICAL"'
      }
    }
    return getResult('/api/serials/series/search', { query, currentPage, pageSize, sort, order })
  },

  getJournalSuggestions(title: string, totalResults: number): Promise<SerialsSuggestion[]> {
    return getResult(`/api/serials/journal/suggestions/${title}?totalResults=${totalResults}`)
  },

  getSeriesSuggestions(title: string, totalResults: number): Promise<SerialsSuggestion[]> {
    return getResult(`/api/serials/series/suggestions/${title}?totalResults=${totalResults}`)
  },

  getJournalMinimal(id: number): Promise<SerialMinimal> {
    return getResult(`/api/serials/journal/minimal/${id}`)
  },

  getSeriesMinimal(id: number): Promise<SerialMinimal> {
    return getResult(`/api/serials/series/minimal/${id}`)
  },
}
